@font-face {
    font-family: 'Futura PT Extra';
    src: url('FuturaPT-ExtraBoldObl.woff2') format('woff2'),
        url('FuturaPT-ExtraBoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-Heavy.woff2') format('woff2'),
        url('FuturaPT-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('FuturaPT-ExtraBold.woff2') format('woff2'),
        url('FuturaPT-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-LightObl.woff2') format('woff2'),
        url('FuturaPT-LightObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-HeavyObl.woff2') format('woff2'),
        url('FuturaPT-HeavyObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-Light.woff2') format('woff2'),
        url('FuturaPT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-Medium.woff2') format('woff2'),
        url('FuturaPT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'July it';
    src: url('Julyit.woff2') format('woff2'),
        url('Julyit.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-MediumObl.woff2') format('woff2'),
        url('FuturaPT-MediumObl.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Black.woff2') format('woff2'),
        url('PlayfairDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-MediumItalic.woff2') format('woff2'),
        url('PlayfairDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-BoldItalic.woff2') format('woff2'),
        url('PlayfairDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-ExtraBold.woff2') format('woff2'),
        url('PlayfairDisplay-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-BlackItalic.woff2') format('woff2'),
        url('PlayfairDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Regular.woff2') format('woff2'),
        url('PlayfairDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Bold.woff2') format('woff2'),
        url('PlayfairDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Italic.woff2') format('woff2'),
        url('PlayfairDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-Bold.woff2') format('woff2'),
        url('FuturaPT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('FuturaPTCond-Bold.woff2') format('woff2'),
        url('FuturaPTCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-ExtraBoldItalic.woff2') format('woff2'),
        url('PlayfairDisplay-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('FuturaPT-BookObl.woff2') format('woff2'),
        url('FuturaPT-BookObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('FuturaPT-BoldObl.woff2') format('woff2'),
        url('FuturaPT-BoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('FuturaPT-Book.woff2') format('woff2'),
        url('FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('PlayfairDisplay-Medium.woff2') format('woff2'),
        url('PlayfairDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('FuturaPTCond-ExtraBold.woff2') format('woff2'),
        url('FuturaPTCond-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('FuturaPTCond-BookObl.woff2') format('woff2'),
        url('FuturaPTCond-BookObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('FuturaPTCond-Medium.woff2') format('woff2'),
        url('FuturaPTCond-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('FuturaPTCond-ExtraBoldObl.woff2') format('woff2'),
        url('FuturaPTCond-ExtraBoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('FuturaPTCond-BoldObl.woff2') format('woff2'),
        url('FuturaPTCond-BoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('FuturaPTCond-Book.woff2') format('woff2'),
        url('FuturaPTCond-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

